import * as React from "react"
import { graphql } from "gatsby";
import styled from '@emotion/styled';

import Container from '../components/layout/container'
import SEO from '../components/seo'

import Accordion from '../components/editorial/accordion'
import RichTextBlock from "../components/editorial/richTextBlock";
import Link from "../components/editorial/link";

import MailIcon from "./../icons/mail.svg";
import IconCallPhone from "./../icons/call-phone.svg";
import IconPin from "./../icons/pin.svg";
import ScrollDownIcon from "./../icons/scrollDownIcon.svg";
import Facebook from "./../icons/Facebook.js";

// styles
import "./../styling/libraries.scss"
import { colors } from "../styling/colors";

import map from "./../images/kart.png";


// data
export const query = graphql`
  query Kontakt {
    sanitySiteSettings {
      companyname
      streetAddress
      zipAndCity
      tel
      email
      emailUsButtonText
    }
    sanityPage(slug: {current: {eq: "kontakt-oss"}}) {
      id
      title
      displayTitle
      intro
      content {
        ... on SanityRichTextBlock {
          _key
          _type
          title
          _rawRichText
        }
      }
    }
    allSanityFaq {
        nodes {
            question
            _rawAnswer
        }
    }
  }
`

const ContactStyling = styled.span`
  a {
    color: ${colors.blue};
    font-weight: 700;
    &.button {
      font-weight: 600;
    }
    &:not(.button) {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: none;
    }
  }
`

// markup
const Aktuelt = ({data}) => {

  const pageData = data ? data.sanityPage : null

  return (
    <Container>

      <SEO 
        title={pageData.title}
        description={pageData.intro}
        pathname="/kontakt-oss"
        // image={data.allSanityPage.edges && data.allSanityPage.edges[0].node.mainImage.asset.fixed}
      />

      <div className="container">

        <div className="section py-0">

          <div className="columns">
            <div className="column is-offset-3 is-6 mb-6 mt-6 has-text-centered">

              {pageData.title || pageData.displayTitle ?
                <h1 className="title is-size-1">{pageData.displayTitle ? pageData.displayTitle : pageData.title}</h1>
              : null}
              
              {pageData.intro && <p className="is-size-5">{pageData.intro}</p>}

            </div>
          </div>

        </div>

      </div>


        <div className="section" style={{'background': colors.green02}}>

          <div style={{
            'margin': '-4em auto 4em', 
            'display': 'flex',
            'justifyContent': 'center',
          }}>
            <ScrollDownIcon />
          </div>
          
          <div className="container">
            <div className="columns">

              <div className="column is-offset-3 is-6">

                <ul>
                  {data.allSanityFaq && data.allSanityFaq.nodes.map((item, index) => 
                    <Accordion key={'faq-item' + index} title={item.question} content={item._rawAnswer} />
                  )}
                </ul>

              </div>

            </div>

          </div>

        </div>


        <div className="section">

          <div className="container">

            <ContactStyling>
  
              <div className="columns">

                <div className="column is-offset-1 is-5">

                  {pageData.content && pageData.content.map((item, index) => {

                    let element;
                    
                    if (item && item._type) {
                      switch(item._type) {
                        case 'richTextBlock':
                          element = <RichTextBlock key={index + item._type} {...item} />
                          break;
                        default: 
                          element = <div key="undefined"/>
                      }
                    }

                    return element;

                  })}

                  {data.sanitySiteSettings.emailUsButtonText && 
                    <Link 
                      className="button" 
                      linkText={data.sanitySiteSettings.emailUsButtonText} 
                      externalURL={`mailto:${data.sanitySiteSettings.email}`}
                    />
                  }

                </div>

                <div className="column is-offset-1 is-4">
                  
                  {/* <Address /> */}

                  <div className="mb-5">
                    <div className="is-flex mb-3">
                      <div className="icon mr-3">
                        <IconPin />
                      </div>
                      <div style={{'flex': '1'}}>
                        <div>{data.sanitySiteSettings.companyname || ''}</div>
                        <div>{data.sanitySiteSettings.streetAddress || ''}</div>
                        <div>{data.sanitySiteSettings.zipAndCity || ''}</div>
                      </div>
                    </div>

                    {data.sanitySiteSettings.tel &&
                      <div className="is-flex mb-3">
                        <div className="icon mr-3">
                          <IconCallPhone />
                        </div>
                        <div style={{'flex': '1'}}>
                          <div>{data.sanitySiteSettings.tel}</div>
                        </div>
                      </div>
                    }

                    {data.sanitySiteSettings.email &&
                      <a href={`mailto:${data.sanitySiteSettings.email}`}>
                        <span className="is-flex mb-3">
                          <span className="icon mr-3">
                            <MailIcon />
                          </span>
                          <span style={{'flex': '1'}}>
                            {data.sanitySiteSettings.email}
                          </span>
                        </span>
                      </a>
                    }

                    {data.sanitySiteSettings.facebook &&
                      <a href={`mailto:${data.sanitySiteSettings.facebook}`}>
                        <span className="is-flex mb-3">
                          <span className="icon mr-3">
                            <Facebook fill={colors.blue} />
                          </span>
                          <span style={{'flex': '1'}}>
                            Facebook
                          </span>
                        </span>
                      </a>
                    }

                  </div>

                  <div className="mb-5">
                      
                    <a rel="noreferrer" target="_blank" href="https://www.google.no/maps/place/VIS/@60.3813487,5.3269121,17z/data=!3m1!4b1!4m5!3m4!1s0x463cf953597c24c1:0x697da36c24a1d063!8m2!3d60.3813487!4d5.3291061" aria-label="Åpner lenke i nytt vindu">
                      <span style={{
                        'lineHeight': '0',
                        'display': 'block',
                        'boxShadow': 'inset -2px 0px 2px rgba(0, 0, 0, 0.15), inset 0px -2px 2px rgba(0, 0, 0, 0.15), inset 4px 2px 2px rgba(0, 0, 0, 0.15), inset 0px 2px 2px rgba(0, 0, 0, 0.15)'
                      }}>
                        <img 
                          src={map} 
                          alt="Kart"
                          style={{'zIndex': '-1', 'position': 'relative'}} 
                        />
                      </span>
                    </a>
                    
                  </div>

                </div>

              </div>

            </ContactStyling>

          </div>
        </div>


    </Container>
  )
}

export default Aktuelt
