import React, { useState } from "react";
import { css } from '@emotion/css';

import RichText from "./portableText";
import ChevronDown from "./../../icons/chevron-down.svg";

import "../../styling/libraries.scss"
import {colors} from "./../../styling/colors";

const AccordionStyle = css`

  .button {
    display: block;
    border: 0;
    border-bottom: 1px solid ${colors.blue03};
    padding: 1em 1em;
    height: auto;
    background: transparent;
    outline: none;
    border-radius: 0;
    transition: .25s;

    &:hover {
      cursor: pointer;
      background: ${colors.green02};
    }

    &:focus {
      border-radius: 3px;
      box-shadow: 0 0 0 3px ${colors.green};
    }

    .title {
      padding-right: 1em;
    }

    .icon {
      transition: .25s;
    }

  }

  .content {
    height: 0;
    visibility: hidden;
    opacity: 0;
    margin: 0 .5em;

    a {
      color: ${colors.blue};
      font-weight: 700;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.open {
    .icon {
      transform: rotate(-180deg);
    }

    .content {
      height: 100%;
      opacity: 1;
      padding: 1em 0;
      visibility: visible;
    }
  }

`

function Accordion (props) {

  const [open, setOpen] = useState(false);

  return (
    <li 
      className={`${AccordionStyle} ${open ? 'open' : ''}`}
      index={props.index}
      key={props.title}>

      <button 
        aria-expanded={open}
        className={`button is-block has-text-left`}
        onClick={() => setOpen(!open)}
        style={{'width': '100%'}}>

        <div className="is-flex is-align-items-center">
          {props.title && <span className="title is-size-5 m-0" style={{'flex': 1}}>{props.title}</span>}
          <div className="icon"><ChevronDown /></div>
        </div>
        
      </button>

      <div className={`content`}>
        {props.content && <RichText portableTextContent={props.content} />}
      </div>

    </li>
  )
}

export default Accordion;
